import React, { useEffect } from 'react';
import Home from '../Home';
type Props = {};

const HomeContainer = (props: Props) => {
  return (
    <>
      <Home />
    </>
  );
};

export default HomeContainer;
